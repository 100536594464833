










































































import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { StakingServiceItemViewModel } from '../viewmodels/staking-service-viewmodel'

@Observer
@Component({})
export default class UpdateAPRDialog extends Vue {
  @Prop({ default: null }) vm!: StakingServiceItemViewModel
}
